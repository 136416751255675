.matchList {
    position: relative;
}

.loadingOverlay {
    position: absolute;
    z-index: 100;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.1);
}

.pagination {
    display: flex;
    justify-content: center;
    margin: 10px 0;
}

.date {
    padding: 15px 0 20px 0;
    text-align: center;
    font-size: 13px;
    text-decoration: underline;
}

.date:not(.first) {
    border-top: 1px solid #ccc;
}

.last10 {
    padding-bottom: 10px;
}

.last10::before {
    content: "";
    display: block;
    margin: 0 auto;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid #ffa500;
}
