.stats {
    font-size: 13px;
    width: 100%;
    border-spacing: 0;
    border-collapse: collapse;
}

.stats thead th {
    text-align: left;
}

.stats tbody:not(:last-child) {
    border-bottom: 1px solid #999;
}

.stats tr:first-child td {
    padding-top: 8px;
}

.stats tr:last-child td {
    padding-bottom: 8px;
}

.stats td:first-child {
    font-weight: bold;
    width: 1px;
    white-space: nowrap;
    padding-right: 10px;
    border-right: 1px solid #999;
}

.stats td:nth-child(2) {
    padding-left: 10px;
}

.stats td {
    padding-bottom: 3px;
}
