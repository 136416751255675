:global * {
    box-sizing: border-box;
}

:global body {
    font-family: sans-serif;
}

:global button, :global select {
    color: #000;
    font-size: 14px;
    background-color: #f0f0f0;
    border-radius: 3px;
    border: 1px solid #000;
    padding: 2px 5px;
    margin: 0;
}

.main {
    max-width: 375px;
    margin: 0 auto;
}

.menu {
    font-size: 13px;
    display: flex;
}

.menu a {
    flex: 0 0 auto;
    color: #0000ee;
}

.menu a:visited {
    color: #0000ee;
}

.logout {
    flex: 0 0 auto;
}
