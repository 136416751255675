.match {
    padding-bottom: 25px;
}

.index {
    text-align: center;
    font-size: 13px;
    color: #999;
    padding-bottom: 4px;
}

.row {
    display: flex;
}

.row > :nth-child(1) {
    width: 40%;
    text-align: right;
}

.row > :nth-child(2) {
    text-align: center;
    width: 20%;
}

.row > :nth-child(3) {
    width: 40%;
}

.score {
    font-size: 18px;
}

.overtime {
    font-size: 13px;
}

.player {
    font-size: 13px;
    color: #999;
}

.player strong {
    color: #008000;
}

.buttons {
    text-align: center;
}

.buttons button {
    font-size: 12px;
    line-height: 12px;
}

.stats {
    font-size: 13px;
}

.stats span.dimmed {
    display: inline-block;
    margin: 0 6px;
    color: #999;
}
