.edit {
    margin-top: 10px;
    text-align: center;
}

.edit input {
    width: 20px;
    text-align: center;
}

.edit input[type='number']::-webkit-inner-spin-button,
.edit input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.penalties {
    font-size: 10px;
}
