.textButton {
    background: none;
    border: none;
    outline: none;
    margin: 0;
    padding: 0;
    font-size: inherit;
    color: #0000ee;
    cursor: pointer;
    text-decoration: underline;
}

